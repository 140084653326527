.card {
  width: 350px;
  height: 550px;
  perspective: 1000px;
  cursor: pointer;
  position: relative;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.card.flipped {
  transform: rotateY(180deg);
}

.cardFront, .cardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

.cardFront {
  background-color: white;
  flex-direction: column;
}

.cardBack {
  background-color: #f8f8f8;
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  height: 100%;
}

.cardTitle {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.cardImage {
  width: 100%;
  height: auto;
  border-radius: 8px;
  cursor: zoom-in;
  transition: transform 0.3s;
}

.cardDescription {
  font-size: 0.9em;
}

.symbols {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding-top: 10px;
}

.symbol {
  font-size: 24px;
  cursor: pointer;
}

.favorite {
  border-color: green;
  box-shadow: 0 0 10px 2px green;
  animation: sparkle 1s infinite;
  position: relative;
}

.vetoed {
  border-color: red;
  box-shadow: 0 0 10px 2px red;
  animation: shimmer 1s infinite;
  background-color: rgba(255, 0, 0, 0.1);
}

@keyframes sparkle {
  0%, 100% {
    box-shadow: 0 0 10px 2px green, 0 0 20px 2px gold;
  }
  50% {
    box-shadow: 0 0 20px 4px green, 0 0 30px 4px gold;
  }
}

@keyframes shimmer {
  0%, 100% {
    box-shadow: 0 0 10px 2px red;
  }
  50% {
    box-shadow: 0 0 20px 4px red;
  }
}

.confetti {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: zoom-out;
}

.modalImage {
  max-width: 90%;
  max-height: 90%;
}
