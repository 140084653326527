.sandClockContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    margin: auto; /* Center the container */
}


.sandClock {
    width: 100%;
    height: 100%;
    fill: #000;
    animation: sandClockAnimation 2s infinite;
}

@keyframes sandClockAnimation {
    0%, 100% {
        fill: #000;
    }
    50% {
        fill: #f90;
    }
}
