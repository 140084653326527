.results {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
}

.movieDetails {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    width: 80%;
    max-width: 600px;
    position: relative; /* Ensure proper positioning for the tease text */
    cursor: pointer; /* Indicate that the div is interactive */
}

.movieDetails h2 {
    margin-bottom: 10px;
    font-size: 1.5em;
}

.movieDetails p {
    margin-bottom: 20px;
    font-size: 1.2em;
}

.watchedStatus {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.watchedStatus div {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 100%; /* Ensure the div takes full width for proper alignment */
}

.watchedStatus label {
    font-size: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; /* Ensure label takes full width */
}

.userName {
    flex: 1; /* Allow the name to take as much space as needed */
    text-align: left; /* Align text to the left */
}

.rating {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex: 2; /* Ensure rating section takes more space */
}

.emoji {
    font-size: 24px;
    margin: 0 5px;
    transition: transform 0.5s;
}

.emoji:hover {
    transform: scale(1.5);
}

/* Add this to your Results.module.css */
.teaseBubble {
    position: absolute;
    top: 20px; /* Adjust to position the bubble appropriately */
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.8); /* 80% transparent black background */
    color: #fff;
    border-radius: 10px;
    font-style: italic;
    font-size: small;
    opacity: 0;
    animation: fadeIn 0.3s forwards;
    z-index: 10; /* Ensure the bubble is on top */
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}
