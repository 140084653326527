/* Import the local Play font */
@font-face {
    font-family: 'Play';
    src: url('../assets/fonts/Play/Play-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Play';
    src: url('../assets/fonts/Play/Play-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

/* Apply the font globally */
body {
  font-family: 'Play', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #ECEAE3; /* Ensure this is set here */
  color: #333;
  line-height: 1.6;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Responsive typography */
body {
  font-size: 16px;
}

@media (max-width: 600px) {
  body {
    font-size: 20px;
  }
}
