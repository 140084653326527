.clockContainer {
    position: fixed;
    top: 10px;
    left: 10px;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.clockDay {
    font-size: 1.2em;
    font-weight: bold;
}

.clockTime {
    font-size: 1em;
}
