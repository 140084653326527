.profileSection {
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-top: 100px; /* Ensure there's enough space between header and profile section */
}

.profileContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 80px; /* Limit the width of each profile container */
}

.profileName {
    margin-top: 3px;
    font-family: 'Play', sans-serif; /* Use your desired font */
    font-size: 13px; /* Adjust font size */
    text-align: center;
}
