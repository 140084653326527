.app {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

.profile-section {
    display: flex;
    justify-content: center;
    gap: 10px; /* Space between profile circles */
    margin-top: 20px;
}

.divider {
    width: 2px;
    background-color: #ccc;
    margin: 0 20px;
}

.main-section {
    flex-grow: 1;
}

.page {
    width: 100%;
    height: 100vh; /* Full viewport height for each page */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.warning {
    display: none;
    color: red;
    background-color: rgba(255, 0, 0, 0.1);
    padding: 10px;
    border-radius: 5px;
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    transition: opacity 0.5s ease-out; /* Add fade-out transition */
}

.warning.show {
    display: block;
    opacity: 1;
}

.warning.hide {
    opacity: 0;
}
