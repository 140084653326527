.profileCircle {
    width: 60px; /* Adjust the width of the profile circle */
    height: 60px; /* Adjust the height of the profile circle */
    border-radius: 50%;
    border: 2px solid transparent;
    cursor: pointer;
    transition: border-color 0.3s;
}

.selected {
    border-color: green; /* Change to thick green */
    border-width: 4px; /* Thicker border */
}

.profileImage {
    width: 100%; /* Ensure the image fits within the profile circle */
    height: auto;
    border-radius: 50%;
}
