/* Basic CSS reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: #ECEAE3; /* Your desired background color */
    color: #333;
    line-height: 1.6;
}

#root {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    padding: 20px; /* Add padding for mobile */
}

@media (max-width: 600px) {
    #root {
        padding: 10px; /* Adjust padding for smaller screens */
    }
}
