.page {
    width: 100%;
    height: 100vh; /* Full viewport height for each page */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    scroll-snap-align: start; /* Snap alignment */
    scroll-snap-stop: always; /* Ensure it snaps to this element */
}
