.home {
    width: 100%;
    height: 100vh; /* Full viewport height for each page */
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll; /* Enable vertical scrolling */
    scroll-snap-type: y mandatory; /* Enable snap scrolling */
    scroll-behavior: smooth; /* Smooth scrolling */
    padding-top: 0; /* Remove padding to avoid misalignment */
}

.page {
    width: 100%;
    height: 100vh; /* Full viewport height for each page */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    scroll-snap-align: start; /* Snap alignment */
    scroll-snap-stop: always; /* Ensure it snaps to this element */
    box-sizing: border-box; /* Ensure padding is included in the element's total width and height */
}


.warning {
    display: none;
    color: red;
    background-color: rgba(255, 0, 0, 0.1);
    padding: 10px;
    border-radius: 5px;
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    transition: opacity 0.5s ease-out; /* Add fade-out transition */
}

.warning.show {
    display: block;
    opacity: 1;
}

.warning.hide {
    opacity: 0;
}

.timerContainer {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    cursor: move; /* Make it movable */
}

.timerTitle {
    margin-bottom: 5px;
    font-size: 0.9em;
}

@media (max-width: 600px) {
    .home {
        padding: 10px; /* Adjust padding for smaller screens */
    }

    .timerContainer {
        bottom: 10px;
        right: 10px;
        padding: 5px;
    }
}
