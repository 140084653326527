.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 80px; /* Increased margin to separate header from profiles */
    position: relative; /* Ensure positioning context */
}

.title {
    font-size: 2em;
    margin-top: 70px; /* Add margin-top to create space between image and title */
    z-index: 1; /* Ensure title stays on top */
}

.imageContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100px; /* Set a fixed height to prevent overlap */
    margin-bottom: 20px; /* Add margin-bottom to create space between image and title */
}

.headerImage {
    width: 200px; /* Set the width */
    height: 200px; /* Set the height */
    border-radius: 50%; /* Soft, rounded corners */
    object-fit: cover; /* Ensure the image covers the container without stretching */
    opacity: 0; /* Start with the image hidden */
    transition: opacity 1s ease-in-out; /* Smooth transition for the image change */
}

.headerImage.visible {
    opacity: 1; /* Show the image */
}

@media (max-width: 600px) {
    .title {
        font-size: 1.5em; /* Adjust title size for mobile */
    }

    .headerImage {
        width: 150px; /* Adjust image size for mobile */
        height: 150px; /* Adjust image size for mobile */
    }
}
